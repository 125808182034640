



















import { Component,Vue,Prop } from 'vue-property-decorator';
@Component({
    name:'itemChildBox',
})
export default class ItemChildBox extends Vue {
    // 获取检索值
    get getSearchValue(){
        return this.$store.state.portal.searchValue;
    }
    // 获取语言
    get getLanguage(){
        return this.$store.state.portal.language;
    }
    // 如果当前有搜索有内容
    get searchFilter(){
        return(name:string)=>{
            if(this.getSearchValue){
                if(name.toLowerCase().indexOf(this.getSearchValue.toLowerCase())!==-1){
                    return true;
                }else{
                    return false;
                }
            }else{
                return true;
            }
        }
    }
    // list
    @Prop({default:Array}) data!:any[]
    // title
    @Prop({default:''}) title!:string;
    // 打开链接
    public goLink(url:any){
        if(url){
            window.open(url,'_blank');
        }
    }
    // 返回
    public back(){
        this.$emit('back')
    }
}
