import i18n from '@/lang';
// 产品/项目
export const productList=[
    {
        image:require('@/assets/images/common/bms.png'),
        name:'BMS',
        nameEn:'BMS',
        subtitle:'BMS 业务管理系统',
        subtitleEn:'BMS - Business management system',
        url:'https://bms.geteducation.net/',
        detail:'业务中台系统，包含了14个管理中心。集合了学校管理，销售管理，财务管理，OA办公，日程提醒等多功能模块于一体，一站式管理的操作系统。',
        detailEN:'The business middle office system includes 14 management centers. It is a one-stop management operating system that integrates school management, sales management, financial management, OA office, schedule reminders and other multi-functional modules.',
    },
    {
        image:require('@/assets/images/common/mso.png'),
        name:'MSOffer',
        nameEn:'MSOffer',
        subtitle:'MSO - My School Offer',
        subtitleEn:'MSO - My School Offer',
        url:'',
        detail:'学生留学平台，目标打造全行业首个生态平台：提供全面的行业咨询；智能选校、智能申请、智能出行、智能旅居',
        detailEN:`Student study abroad platform, the goal is to build the industry's first ecological platform: provide comprehensive industry consulting; smart school selection, smart application, smart travel, smart residence`,
        child:[
            {
                name:'香港站',
                url:'https://www.msoffer.hk/portal2'
            },
            {
                name:'中国站',
                url:'https://www.msoffer.cn/portal1'
            },
            {
                name:'香港站（测试版）',
                url:'http://219.135.153.223:6088/HK'
            },
            {
                name:'中国站（测试版）',
                url:'http://219.135.153.223:6088/CHN'
            }
        ]
    },
    {
        image:require('@/assets/images/common/ibs.png'),
        name:'IBS',
        nameEn:'IBS',
        subtitle:'IBS',
        subtitleEn:'IBS',
        url:'',
        detail:'Information Board System（校代学校信息发布平台），专门提供给校代使用的业务系统：提供学校信息管理，各业务应用的学校信息维护，如：年会，网上展会等',
        detailEN:'Information Board System (School Information Publishing Platform), a business system specially provided for school representatives: providing school information management, school information maintenance for various business applications, such as: annual meetings, online exhibitions, etc.',
        child:[
            {
                name:'IBS(官网)',
                url:'https://ibs.geteducation.net/'
            },
            {
                name:'IBS(内容管理)',
                url:'https://ibs.geteducation.net/login'
            }
        ]
    },
    {
        image:require('@/assets/images/common/issue.png'),
        name:'ISSUE',
        nameEn:'ISSUE',
        subtitle:'ISSUE',
        subtitleEn:'ISSUE',
        url:'',
        detail:'ISSUE-一休（代理业务管理平台），专门提供给代理使用的业务系统：提供学生申请管理，推荐重要的行业资讯，查询院校佣金信息，留学技能培训等',
        detailEN:'ISSUE-Yixiu (agency business management platform), a business system specifically provided to agents: provide student application management, recommend important industry information, query college commission information, study abroad skills training, etc.',
        child:[
            {
                name:'ISSUE(GEA)',
                url:'https://www.issueschool.com/'
            },
            {
                name:'ISSUE(IAE)',
                url:'https://iae.issueschool.com/'
            },
        ]
    },
    {
        image:require('@/assets/images/common/vfair.png'),
        name:'Vfair',
        nameEn:'Vfair',
        subtitle:'Vfair 线上展会平台',
        subtitleEn:'Vfair - Online exhibition platform',
        url:'',
        detail:'vfair展会平台提供了一个由学生、代理、校代，三种角色互相交互，相互拓展，促进业务达成的一个多功能平台。平台以代理为出发点，借助平台的资源，让他们能举办一个属于自己的展会。',
        detailEN:'The vfair exhibition platform provides a multifunctional platform where students, agents, and school representatives interact with each other, expand each other, and promote business. The platform takes the agency as the starting point and uses the resources of the platform to allow them to hold an exhibition of their own.',
        child:[
            {
                name:'英国本科补录线上展会（10.26）',
                url:'https://www.getec.vip/code/51b2/index.html#/',
            },
            {
                name:'2021秋季在线国际教育展（9.25）',
                url:'https://www.getec.vip/?vfair=tgul&lang=cn',
            },
            {
                name:'與學校代視像面試，快速取錄（7.31）',
                url:'https://www.getec.vip/?vfair=yayf&lang=zh',
            },
            {
                name:'另辟蹊径，出奇制胜高考后留学（7.17）',
                url:'https://www.getec.vip/?vfair=5dnt&lang=cn',
            },
            {
                name:'2021线上国际教育展（3.20）',
                url:'https://www.getec.vip/?vfair=v0we&lang=cn',
            },
            {
                name:'vFair(后台系统)',
                url:'https://advfair.getec.vip/get-admin/',
            },
            {
                name:'vFair(代理端)',
                url:'https://advfair.getec.vip/get-admin/agent/',
            },
            {
                name:'vFair(老师端)',
                url:'https://advfair.getec.vip/get-admin/teacher/',
            },
        ]
    },
    {
        image:'',
        name:'CPP',
        nameEn:'CPP',
        subtitle:'CPP',
        subtitleEn:'CPP',
        url:'',
        detail:'旧版业务系统，主要处理学生申请信息，跟踪学生申请状态。',
        detailEN:'The old version of the business system mainly processed student application information and tracked student application status.',
        child:[
            {
                name:'CPP(GEA)',
                url:'https://www.geaworld.org/mainland/'
            },
            {
                name:'CPP(IAE)',
                url:'https://iae.geteducation.net/'
            }
        ]
    },
    {
        image:'',
        name:'MPS',
        nameEn:'MPS',
        subtitle:'MPS 市场佣金管理系统',
        subtitleEn:'MPS - Market Commission Management System',
        url:'https://mps.geaworld.org/',
        detail:'旧版佣金管理系统，主要维护及展示各院校佣金信息',
        detailEN:'The old commission management system mainly maintains and displays the commission information of various institutions',
    },
    {
        image:require('@/assets/images/common/xiaowu.png'),
        name:'留学小屋',
        nameEn:'PBSA',
        subtitle:'留学小屋',
        subtitleEn:'PBSA',
        url:'http://cabin.geteducation.net/cabin/',
        detail:'使用Casita接口信息，展示学校周边住房信息的小程序',
        detailEN:'A small program that uses Casita interface information to display housing information around the school',
    },
]
// 研发/工具
export const toolList:any=[
    {
        image:require('@/assets/images/common/confluence.png'),
        name:'Confluence',
        nameEn:'Confluence',
        subtitle:'',
        subtitleEn:'',
        url:'http://106.52.34.56:8090/',
        detail:'Confluence是一个专业的企业知识管理与协同软件，也可以用于构建企业wiki。使用简单，但它强大的编辑和站点管理特征能够帮助团队成员之间共享信息、文档协作、集体讨论，信息推送。',
        detailEN:'Confluence is a professional enterprise knowledge management and collaboration software, which can also be used to build enterprise wikis. Simple to use, but its powerful editing and site management features can help team members share information, document collaboration, group discussions, and push information.'
    },
    {
        image:require('@/assets/images/common/chandao.png'),
        name:'禅道',
        nameEn:'ZenTao',
        subtitle:'',
        subtitleEn:'',
        url:'http://106.52.34.56:20180/',
        detail:'禅道是一款专业的国产开源研发项目管理软件，集产品管理、项目管理、质量管理、文档管理、组织管理和事务管理于一体，完整覆盖了研发项目管理的核心流程。',
        detailEN:'ZenTao is a professional domestic open source R&D project management software that integrates product management, project management, quality management, document management, organization management and affairs management, and completely covers the core process of R&D project management.'
    },
    {
        image:require('@/assets/images/common/Jenkins.png'),
        name:'Jenkins',
        nameEn:'Jenkins',
        subtitle:'',
        subtitleEn:'',
        url:'http://106.52.34.56:38080/',
        detail:'Jenkins是一个开源的、提供友好操作界面的持续集成(CI)工具，起源于Hudson（Hudson是商用的），主要用于持续、自动的构建/测试软件项目。',
        detailEN:'Jenkins is an open source continuous integration (CI) tool that provides a user-friendly interface. It originated from Hudson (Hudson is commercially available) and is mainly used for continuous and automatic construction/testing of software projects.'
    },
    {
        image:require('@/assets/images/common/gitlab.png'),
        name:'GitLab',
        nameEn:'GitLab',
        subtitle:'',
        subtitleEn:'',
        url:'http://106.52.34.56:10090/users/sign_in',
        detail:'GitLab 是一个用于仓库管理系统的开源项目，使用Git作为代码管理工具，并在此基础上搭建起来的Web服务。',
        detailEN:'GitLab is an open source project for a warehouse management system. It uses Git as a code management tool and builds a web service on this basis.'
    },
    {
        image:require('@/assets/images/common/wangyi.png'),
        name:'网易企业邮箱',
        nameEn:'Netease enterprise mailbox',
        subtitle:'',
        subtitleEn:'',
        url:'http://mail.geteducation.org/',
        detail:'网易企业邮箱登录入口（geteducation.org）',
        detailEN:'NetEase corporate mailbox login entrance (geteducation.org)'
    },
    {
        image:require('@/assets/images/common/aliMail.png'),
        name:'阿里企业邮箱',
        nameEn:'Alibaba enterprise email',
        subtitle:'',
        subtitleEn:'',
        url:'http://mail.geaworld.org/',
        detail:'阿里企业邮箱登录入口（geaworld.org）',
        detailEN:'Ali enterprise mailbox login entrance (geaworld.org)'
    },
    {
        image:require('@/assets/images/common/txhy.png'),
        name:'腾讯会议',
        nameEn:'Tencent Conference',
        subtitle:'',
        subtitleEn:'',
        url:'wemeet://',
        spareUrl:'https://meeting.tencent.com/user-center/start',
        localName:'tencentMeeting',
        detail:'全平台一键接入、音视频智能降噪、美颜、背景虚化、锁定会议、屏幕水印等功能。该软件提供实时共享屏幕、支持在线文档协作',
        detailEN:'Full platform one-key beautification, audio and video intelligent capture, face value, background blur, lock meeting, screen watermark and other functions. The software provides real-time screen sharing and supports online document collaboration'
    },
    // {
    //     image:require('@/assets/images/common/tapd.png'),
    //     name:'TAPD',
    //     subtitle:'',
    //     url:'https://www.tapd.cn/',
    //     detail:'TAPD是...',
    //     detailEN:'TAPD is....'
    // },
    // {
    //     image:require('@/assets/images/common/Processon.png'),
    //     name:'Processon',
    //     subtitle:'',
    //     url:'https://www.processon.com/',
    //     detail:'Processon是...',
    //     detailEN:'Processon is....'
    // },
    // {
    //     image:require('@/assets/images/common/lanhu.png'),
    //     name:'蓝湖',
    //     subtitle:'',
    //     url:'https://lanhuapp.com/',
    //     detail:'蓝湖是...',
    //     detailEN:'LanHu is....'
    // },
    // {
    //     image:require('@/assets/images/common/tengxun.png'),
    //     name:'腾讯文档',
    //     subtitle:'',
    //     url:'https://docs.qq.com/',
    //     detail:'腾讯文档是...',
    //     detailEN:'Tencent documents is....'
    // },
    // {
    //     image:require('@/assets/images/common/jinshan.png'),
    //     name:'金山文档',
    //     subtitle:'',
    //     url:'https://www.kdocs.cn/',
    //     detail:'金山文档是...',
    //     detailEN:'Jinshan document is....'
    // },
    // {
    //     image:require('@/assets/images/common/Online.png'),
    //     name:'在线Photoshop',
    //     subtitle:'',
    //     url:'https://www.photopea.com/',
    //     detail:'在线Photoshop是...',
    //     detailEN:'Online Photoshop is....'
    // },
    // {
    //     image:require('@/assets/images/common/yasuo.png'),
    //     name:'图片压缩',
    //     subtitle:'',
    //     url:'https://tinypng.com/',
    //     detail:'图片压缩是...',
    //     detailEN:'Picture compression is....'
    // },
    // {
    //     image:require('@/assets/images/common/code.png'),
    //     name:'编码转换',
    //     subtitle:'',
    //     url:'https://tool.chinaz.com/Tools/Unicode.aspx',
    //     detail:'编码转换是...',
    //     detailEN:'Code conversion is....'
    // },
]