
























import { Component,Vue,Prop, Watch } from 'vue-property-decorator';
import detail from './detail.vue'
import { PortalModule } from '@/store/modules/portal';
import i18n from '@/lang';
@Component({
    name:'itemBox',
    components:{detail}
})
export default class ItemBox extends Vue {
    // 获取是否展示介绍
    get getShowIntroduce(){
        return this.$store.state.portal.showIntroduce;
    }
    // 获取对应的下标
    get getItemIndex(){
        return this.$store.state.portal.itemIndex;
    }
    // 获取语言
    get getLanguage(){
        return this.$store.state.portal.language;
    }
    // 获取语言
    get getErrorLog(){
        return this.$store.state.portal.errorLog;
    }
    // item
    @Prop({default:() => new Object()}) item!:any;
    // 当前第几个图标
    @Prop({default:null}) itemIndex!:number
    // 位置坐标是否能显示详情
    public detailShowLeft:boolean=false;
    // 检测iframe是否加载成功
    public iframe:boolean=false;
    public handleLogo(){
        if(this.item.child&&this.item.child.length){
            this.$emit('showChild',this.item);
        }else{
            this.goLink();
        }
    }
    // 跳转
    public async goLink(){
        if(this.item.url){
            if(this.item.name==='腾讯会议'){
                if(this.getLocalStorage()){
                    window.open(this.item.url,'_blank');
                }else{
                    this.confirm();
                }
            }else{
                window.open(this.item.url,'_blank')
            }
        }
    }
    // 确认框
    public confirm(){
        this.$confirm((i18n.t('portal.downloadTencentMeeting') as string),(i18n.t('portal.tip') as string), {
          confirmButtonText:(i18n.t('portal.yse') as string),
          cancelButtonText:(i18n.t('portal.no') as string),
          type: 'info',
          closeOnPressEscape:false,
          closeOnClickModal:false,
          distinguishCancelAndClose:true
        }).then(() => {
            this.setLocalStorage('yes');
            window.open(this.item.url,'_blank');
        }).catch((action) => {
            if(action==='cancel'){
                window.open(this.item.spareUrl,'_blank');
            }
        });
    };
    // 设置localStorage
    public setLocalStorage(type:string){
        const localName:any=this.item.localName;
        if(type==='yes'){
            localStorage.setItem(localName,type);
        }
    }
    // 获取localStorage
    public getLocalStorage(){
        const localName:any=this.item.localName;
        const localValue=localStorage.getItem(localName);
        if(localValue==='yes'){
            return true;
        }else{
            return false;
        }
    }
    // 计算元素距离浏览器左边的数值
    public getLeft(obj:any){
        let offset=obj.offsetLeft;
        if(obj.offsetParent!=null) offset +=this.getLeft(obj.offsetParent);
        return offset;
    }
    // 查看当前元素距离显示屏左边是否够230px显示详情内容
    public getElShowLeftDetail(el:any){
        const detailWidth:number=230;
        const offset=this.getLeft(el);
        if(offset>detailWidth){
            return true;
        }else{
            return false;
        }
    }
    // 设置详情
    public setDetail(){
        const el=(this.$refs.detailImage as any);
        this.detailShowLeft=this.getElShowLeftDetail(el)
        if(this.getLanguage==='zh-cn'){
            PortalModule.SetDetail(this.item.detail);
        }else{
            PortalModule.SetDetail(this.item.detailEN);
        }
    }
    // 删除详情
    public delDetail(){
        PortalModule.SetDetail('');
    }
}
