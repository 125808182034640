








































import { Component, Vue,Watch } from 'vue-property-decorator';
import itemBox from './portal/item-box.vue';
import itemChildBox from './portal/item-child-box.vue';
import pager from './portal/pager.vue';
import {productList,toolList} from '@/assets/data/item';
import { PortalModule } from '@/store/modules/portal';
@Component({
  name:'portalMain',
  components:{
    itemBox,
    itemChildBox,
    pager,
  }
})
export default class PortalMain extends Vue {
  // 获取检索值
  get getSearchValue(){
    return this.$store.state.portal.searchValue;
  }
  // 页数
  get getCount(){
    return this.$store.state.portal.count;
  }
  // 是否显示父级
  get getShowParent(){
    return this.$store.state.portal.showParent;
  }
  // 是否显示子级
  get getShowChild(){
    return this.$store.state.portal.showChild;
  }
  public created(){
    this.allList=[
      ...this.productList,
      ...this.toolList
    ]
    this.checkQuery();
  }
  // 当检索发生变化 则查找对应的内容，否则当前tab高亮哪个就显示哪个列表
  @Watch('getSearchValue')
  changeList(){
    if(this.getSearchValue){
        this.showList=this.allList.filter((item:any)=>{
        if(item.name.toLowerCase().indexOf(this.getSearchValue.toLowerCase())!==-1){
          return item;
        }else if(item.child&&item.child.length){
          const childList:any=item.child;
          for(const key of childList){
            if(key.name.toLowerCase().indexOf(this.getSearchValue.toLowerCase())!==-1){
              return item;
            }
          }
        }
      })
      this.pageList=this.setPageList(this.showList);
    }else{
      this.checkQuery();
    }
  }
  // 当路由变化
  @Watch('$route.query')
  changeRouteQuery(){
    this.checkQuery();
  }
  public mounted(){
    PortalModule.SetShowParent(false);
    setTimeout(()=>{
      PortalModule.SetShowParent(true);
    },this.animationTime);
  }
  // 动画时间
  public animationTime:number=300;
  // 单个数据
  public dataObj:any=[];
  // 总表（全局搜索用）
  public allList:any=[];
  // 显示用的列表（单页显示用）
  public showList:any=[];
  // 分页数据
  public pageList:any=[];
  // 产品中心列表数据源
  public productList:any=productList;
  // 研发中心列表数据源
  public toolList:any=toolList;

  // 当前页
  public thisPage:number=0
  // 查看当前路由的query值变化
  public checkQuery(){
    const tab=this.$route.query.tab;
      if(tab==='product'){
        this.showList=this.productList;
      }else if(tab==='dev'){
        this.showList=this.toolList;
      }else if(!tab){
        this.showList=this.productList;
      }else{
        this.showList=[];
      }
      PortalModule.SetShowParent(false);
      PortalModule.SetShowChild(false);
      setTimeout(()=>{
        PortalModule.SetShowParent(true);
        this.pageList=this.setPageList(this.showList);
      },this.animationTime);
  }
  // 显示子级
  public showChild(data:any){
    PortalModule.SetShowParent(false);
    setTimeout(()=>{
      this.dataObj=data;
      PortalModule.SetShowChild(true);
    },this.animationTime)
  }
  // 显示父级
  public back(){
    PortalModule.SetShowChild(false);
    setTimeout(()=>{
      PortalModule.SetShowParent(true);
    },this.animationTime)
  }
  // 翻页
  public nextPage(thisPage:number,animation?:boolean){
    PortalModule.SetShowParent(false);
    this.thisPage=thisPage;
    setTimeout(()=>{
      PortalModule.SetShowParent(true);
    },this.animationTime);
  }
  // 重组页面数据
  public setPageList(list:any){
    if(list.length){
      const listArr:any=[];
      let index = 0;
      while(index < list.length) {
        listArr.push(list.slice(index,index+=this.getCount));
      }
      return listArr
    }else{
      return []
    }
  }
  // 设置index
  public setItemIndex(index:number){
    PortalModule.SetItemIndex(index);
  }
  // 清除index
  public delItemIndex(){
    PortalModule.SetItemIndex(null);
  }
}
