











import { Component,Vue,Prop, Watch } from 'vue-property-decorator';
@Component({
    name:'itemBox',
})
export default class ItemBox extends Vue {
    // 页条
    @Prop({default:0}) count!:number;
    // 总条
    @Prop({default:0}) allCount!:number;
    @Watch('allCount')
    changeAllCount(){
        this.setPage();
    }
    // 页码
    public pager:number=0;
    // 当前页码
    public thisPage:number=0;

    public mounted(){
        this.setPage();
    }
    // 设置页码
    public setPage(){
        this.thisPage=1;
        if(this.allCount>0){
            if(this.allCount%this.count===0){
                this.pager=this.allCount/this.count
            }else{
                if(this.allCount<this.count){
                    this.pager=1;
                }else{
                    this.pager=Number((this.allCount/this.count).toFixed(0))+1
                }
            }
        }else{
            this.pager=0;
        }
        this.output(this.thisPage)
    }
    // 点击分页
    public handlePage(item:number){
        if(this.thisPage!==item){
            this.thisPage=item;
            this.output(item,true);
        }
    }
    // output
    public output(page:any,animation?:boolean){
        this.$emit('nextPage',page-1,animation)
    }
}
