













import { Component,Vue,Prop } from 'vue-property-decorator';
@Component({
    name:'itemDateil',
})
export default class ItemDateil extends Vue {
    // 左边是否够位置显示详情信息
    @Prop({}) detailShowLeft!:any;
    // 获取详情
    get getDetail(){
        return this.$store.state.portal.detail;
    }
}
