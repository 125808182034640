





import { Component, Vue } from 'vue-property-decorator';
import portalMain from '@/components/portal_main.vue';
@Component({
  name: 'index',
  components: { portalMain },
})
export default class extends Vue {}
